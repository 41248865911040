import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CometChat } from "@cometchat-pro/chat";
import { Constants as COMETCHAT_CONSTANTS } from "src/app/constants";
import { CometService } from "../../comet.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-kitchen-sink-app",
  templateUrl: "./kitchen-sink-app.component.html",
  styleUrls: ["./kitchen-sink-app.component.scss"],
})
export class KitchenSinkAppComponent implements OnInit {
  userInput: String = "";
  superHero1 = "https://data-us.cometchat.io/assets/images/avatars/ironman.png";
  superHero2 =
    "https://data-us.cometchat.io/assets/images/avatars/captainamerica.png";
  superHero3 =
    "https://data-us.cometchat.io/assets/images/avatars/spiderman.png";
  superHero4 =
    "https://data-us.cometchat.io/assets/images/avatars/wolverine.png";
  superHero5 = "https://data-us.cometchat.io/assets/images/avatars/cyclops.png";
  user: any;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private cometService: CometService
  ) { }

  onLoginError: boolean = false;
  errorMsg: string = "";

  ngOnInit() {
    this.getUser();
    //console.log('kitchen sink app loaded');
    //Create User
    // let  user = new CometChat.User('testing');
    // user.setName('Sohail');
    // CometChat.createUser(user, COMETCHAT_CONSTANTS.AUTH_KEY).then(
    //   user => {
    //       console.log("user created", user);
    //   },error => {
    //       console.log("error", error);
    //   }
    // )
  }

  createUser(data) {
    //Create User
    let user = new CometChat.User(data.uid);
    user.setName(data.name);
    CometChat.createUser(user, COMETCHAT_CONSTANTS.AUTH_KEY).then(
      user => {
        console.log("user created", user);
        this.onLogin(this.user.id + '_' + this.user.first_name + '_' + this.user.last_name);
      }, error => {
        this.onLogin(this.user.id + '_' + this.user.first_name + '_' + this.user.last_name);
        console.log("error", error);
      }
    )
  }

  getUser() {
    const formData: FormData = new FormData();
    formData.append('token', localStorage.getItem("auth"));
    this.cometService.getUser(formData).subscribe(res => {
      if (!res.status) {
        this.toastr.error(res?.message);
        localStorage.setItem('auth', '');
        this.router.navigate(["/authentication/signin"])
        return;
      }
      this.user = res.user;
      this.createUser({
        uid: this.user.id + '_' + this.user.first_name + '_' + this.user.last_name,
        name: this.user.first_name + ' ' + this.user.last_name
      });
    });
  }

  /**
   * Get User info by using the UID for logging a user in
   * @param {String} UID
   */
  onLogin(UID) {
    console.log(UID);
    CometChat.login(UID, COMETCHAT_CONSTANTS.AUTH_KEY).then(
      (user) => {
        console.log("Login Successful:", { user });
        this.router.navigate(["/comet/home"]);
      },
      (error) => {
        console.log("Login failed with exception:", { error });
        this.onLoginError = true;
        this.errorMsg = error.message;
        this.createUser({
          uid: this.user.id,
          name: this.user.first_name + ' ' + this.user.last_name
        });
      }
    );
  }
}
