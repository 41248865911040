import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../shared/shared.service';
import { ActivatedRoute } from '@angular/router';
import { DashboardService } from '../dashboard/dashboard.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  settings: any = {};
  historylist: any[];
  organizations: any;
  users: any;
  contentlibrary: any;
  collections: any;
  tags: any;
  search_script: any;
  resource_script: any;
  org_list: any[];
  partner_list: any[];
  userId: any;
  params: any;
  notifications: any = {};
  modalRef: BsModalRef;
  forms = <any>{};
  Aforms = <any>{};
  aanouncementForm: FormGroup;
  returnedArray: any[];
  currentPage = 1;
  length: any;
  sortOrder: any = 'ASC';
  bsConfig: Partial<BsDatepickerConfig>;
  file!: File;
  sendingNotification: boolean = false;

  pages = [
    { 'key': 'home', 'title': 'Home' },
    { 'key': 'resources', 'title': 'Resources' },
    { 'key': 'prayer', 'title': 'Prayer' },
    { 'key': 'connect', 'title': 'Connect' },
    { 'key': 'profile', 'title': 'Profile' },
    { 'key': 'podcast', 'title': 'Podcast' },
    { 'key': 'feedback', 'title': 'Feedback' },
    { 'key': 'chat', 'title': 'Chat' },
    { 'key': 'help', 'title': 'Help' },
    { 'key': 'contactus', 'title': 'Contact us' },
  ];
  id: any; x

  constructor(
    private _service: DashboardService,
    private service: SharedService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe
  ) { }

  ngOnInit() {
    this.bsConfig = Object.assign({}, { dateInputFormat: 'MM/DD/YYYY' });

    this.params = 1;
    this.listAll();


  }
  // showAnnouncement() {
  //   this.service.showAnnouncement().subscribe(response => {
  //     this.Aforms.title = response[0]?.title;
  //     this.Aforms.link = response[0]?.link;
  //     this.Aforms.date = response[0]?.date;
  //     this.Aforms.description = response[0]?.description;

  //   })
  // }


  decline() {
    this.modalRef.hide();
  }

  sortTable(n, sortColumn = '') {

    let table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
    table = document.getElementById("myTable");
    switching = true;

    this.sortOrder = this.sortOrder == 'ASC' ? 'DESC' : 'ASC';
    // dir = "asc";
    let tempArr = this.notifications.sort((a, b) => {
      console.log(a);
      console.log(b);
      var data1;
      var data2;
      switch (sortColumn) {

        default:
          if (a) {
            data1 = a[sortColumn].toUpperCase(); // ignore upper and lowercase
          }
          if (b) {
            data2 = b[sortColumn].toUpperCase(); // ignore upper and lowercase
          }
          break;
      }

      if (data1 < data2) {
        return this.sortOrder == 'ASC' ? -1 : 1;
      }
      if (data1 > data2) {
        return this.sortOrder == 'ASC' ? 1 : -1;
      }
      // names must be equal
      return 0;
    })
    this.paginationList(tempArr);
  }

  listAll(param: any = '') {
    this.service.listAll(this.params).subscribe(response => {
      if (response.status == true) {
        this.notifications = response.info;
        this.paginationList(this.notifications);
        this.length = this.notifications.length;
      } else {

      }
    })
  }

  addNotification(template: any, param = '') {
    this.modalRef = this.modalService.show(template, {});
  }
  addAnnouncement(template2: any, param = '') {
    this.modalRef = this.modalService.show(template2, {});
    // this.showAnnouncement()
  }

  onFileChange(event) {
    this.file = event.target.files[0];
  }


  paginationList(tagslist: any) {
    if (this.currentPage != 1) {
      const startItem = (this.currentPage - 1) * 15;
      const endItem = this.currentPage * 15;
      this.returnedArray = this.notifications.slice(startItem, endItem);
    }
    else {
      this.returnedArray = tagslist.slice(0, 15);
    }
  }

  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.returnedArray = this.notifications.slice(startItem, endItem);
    this.currentPage = event.page;
  }

  sendNotification(forms) {
    console.log(forms.page);

    if (forms.page === '' || forms.message === '' || forms.title === '') {

    }
    if (confirm('Are you sure you want to send notification?')) {
      this.sendingNotification = true;
      this.service.sendnotification(forms).subscribe(response => {
        if (response.status == true) {
          this.toastr.success('Success', 'Thank you, your notification was sent.');
          this.forms = {};
          this.listAll();
          this.sendingNotification = false;
          this.modalRef.hide();
        } else {
          this.toastr.error('error', response.message);
        }
      })
    }

  }
  test(e: any) {
    console.log('ss', e);
    var fdate = this.datepipe.transform(e[0], 'MM/dd/yyyy');
    var edate = this.datepipe.transform(e[1], 'MM/dd/yyyy');
    this.Aforms.date = fdate + " - " + edate;
    console.log(this.Aforms);

  }

  sendAnnouncement(Aforms) {
    const formData: FormData = new FormData();
    formData.append('img', this.file);
    formData.append('title', Aforms?.title);
    formData.append('description', Aforms?.description);
    formData.append('link', Aforms?.link);
    formData.append('date', Aforms?.date);

    this.service.updateAnnouncement(formData).subscribe(response => {
      if (response.status == true) {
        this.toastr.success('Success', response.message);
        this.modalRef.hide();
      } else {
        this.toastr.error('error', response.message);
      }
    })
  }
  resetAnnouncement(Aforms) {
    Aforms.title = null;
    Aforms.description = null;
    Aforms.link = null;
    Aforms.date = null;

  }

}
