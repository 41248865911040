import { Injectable } from '@angular/core';
import { Constants } from '../constants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ChatLogService {
  private url = Constants.API_URL;
  constructor(private http: HttpClient) { }

  getChatLog(data) {
    return this.http.post(this.url + "allchat", data);
  }

  deletChatLog(data) {
    return this.http.post(this.url + "deleteChat", data);
  }
}
