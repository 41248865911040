import { Component, OnInit } from '@angular/core';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { ChatLogService } from './chat-log.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-chat-log',
  templateUrl: './chat-log.component.html',
  styleUrls: ['./chat-log.component.css']
})
export class ChatLogComponent implements OnInit {

  length: any;
  Aforms: any = { support_uid: '', date: null, search: '' };
  chatList: any;
  returnedArray: any;
  currentPage = 1;
  bsConfig: Partial<BsDatepickerConfig>;
  sortOrder: any = 'ASC';
  volunteerDropDown: any[] = [];
  constructor(private _service: ChatLogService,
    public datepipe: DatePipe) { }

  ngOnInit(): void {
    this.bsConfig = Object.assign({}, { dateInputFormat: 'MM/DD/YYYY' });
    this.listAll();
  }

  listAll() {
    let formdata = new FormData();
    if (this.Aforms?.fdate && this.Aforms?.edate) {
      formdata.append('startDate', this.Aforms?.fdate)
      formdata.append('endDate ', this.Aforms?.edate)
    }
    if (this.Aforms?.support_uid) {
      formdata.append('support_uid', this.Aforms?.support_uid)
    }
    if (this.Aforms?.search) {
      formdata.append('search', this.Aforms?.search)
    }

    this._service.getChatLog(formdata).subscribe((response: any) => {
      if (response?.status == true) {
        this.chatList = response.data;
        this.length = this.chatList.length;
        this.paginationList(this.chatList);
        if (!this.volunteerDropDown.length) {
          this.volunteerDropDown = this.getvolunteerDropDown(this.chatList);
        }
      }
    })
  }

  paginationList(tagslist: any) {
    if (this.currentPage != 1) {
      const startItem = (this.currentPage - 1) * 15;
      const endItem = this.currentPage * 15;
      this.returnedArray = tagslist.slice(startItem, endItem);
    }
    else {
      this.returnedArray = tagslist.slice(0, 15);
    }
  }

  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.returnedArray = this.chatList.slice(startItem, endItem);
    this.currentPage = event.page;
  }

  test(e: any) {
    if (e) {
      var fdate = this.datepipe.transform(e[0], 'yyyy-MM-dd');
      var edate = this.datepipe.transform(e[1], 'yyyy-MM-dd');
      this.Aforms = {
        ...this.Aforms,
        fdate, edate
      }
    }
  }
  resetFilter() {
    this.Aforms = { support_uid: '', date: null, search: '' };
    this.listAll();
  }

  deleteChatLog(chatLogId) {
    if (!chatLogId) return;
    let formdata = new FormData();
    formdata.append('id', chatLogId)

    this._service.deletChatLog(formdata).subscribe((response: any) => {
      if (response?.status == true) {
        this.listAll();
      }
    })
  }

  sortTable(n, sortColumn = '') {

    let table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
    table = document.getElementById("myTable");
    switching = true;

    this.sortOrder = this.sortOrder == 'ASC' ? 'DESC' : 'ASC';
    // dir = "asc";
    let tempArr = this.chatList.sort((a, b) => {
      var data1;
      var data2;
      switch (sortColumn) {

        default:
          if (a) {
            data1 = a[sortColumn]?.toUpperCase(); // ignore upper and lowercase
          }
          if (b) {
            data2 = b[sortColumn]?.toUpperCase(); // ignore upper and lowercase
          }
          break;
      }

      if (data1 < data2) {
        return this.sortOrder == 'ASC' ? -1 : 1;
      }
      if (data1 > data2) {
        return this.sortOrder == 'ASC' ? 1 : -1;
      }
      // names must be equal
      return 0;
    })
    this.paginationList(tempArr);
  }

  getvolunteerDropDown(data = []) {
    if (!data.length) return;
    const uniqueSet = new Set();
    const uniqueObjects = data.filter(obj => {
      const value = obj['support_uid'];
      if (!uniqueSet.has(value)) {
        uniqueSet.add(value);
        return true;
      }
      return false;
    });
    return uniqueObjects;
  }

  formatDuration(second = 0) {
    const date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(second);
    // Format the date using the datepipe
    return this.datepipe.transform(date, second >= 60 * 60 ? 'hh:mm:ss' : 'mm:ss');
  }
}