import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
// import { NavbarService } from './navbar.service';
import { ToastrService } from 'ngx-toastr';

import { Router, ActivatedRoute } from '@angular/router';
import { from } from 'rxjs';
import { SharedService } from '../shared/shared.service';
declare const $: any;
@Component({
	selector: 'navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
	urlActive = <any>{};
	userdetail = <any>{};
	user: any = {};
	params: number;
	org_list = <any>[];
	organization: string;
	item: any;
	versionError: any;

	constructor(private toastr: ToastrService,
		private authService: AuthenticationService,
		private _service: SharedService,
		private router: Router,
		private route: ActivatedRoute) { }

	ngOnInit() {



		var data = JSON.stringify(localStorage.getItem('user'))
		this.item = JSON.parse(data);
		let value = localStorage.getItem('auth');
		// if(!(value) || value == null || this.item == null){
		//     this.router.navigate(["authentication/signin"]);
		// }

		console.log(this.item);

		if (Object.keys(this.user).length == 0) {

			if (localStorage.user) {
				let user = JSON.parse(localStorage.user)
				this.authService.userInfo.next(user);
			}
		}

		this.authService.user.subscribe((res) => {
			this.user = res;
		});
		console.log(this.user);

		$('.sidebar .menu .list').slimscroll({
			height: 'calc(100vh - 65px)',
			color: 'rgba(0,0,0,0.2)',
			position: 'left',
			size: '2px',
			alwaysVisible: false,
			borderRadius: '3px',
			railBorderRadius: '0'
		});

		this.userdetail = JSON.parse(localStorage.getItem('user'));
		this.params = 1;
		this.getUrlPath();
		// this.organization = this.userdetail.org_id;
	}



	getUrlPath() {

		this.urlActive['dashboard'] = false;
		this.urlActive['organization'] = false;
		this.urlActive['users'] = false;
		this.urlActive['mainresources'] = false;
		this.urlActive['library'] = false;
		this.urlActive['collections'] = false;
		this.urlActive['tags'] = false;
		this.urlActive['statistics'] = false;
		this.urlActive['survey'] = false;
		this.urlActive['settings'] = false;
		this.urlActive['searchscript'] = false;
		this.urlActive['resourcescript'] = false;
		// this.urlActive['comet'] = false;
		this.urlActive['chat-log'] = false;
		this.urlActive['canned-resource'] = false;
		this.urlActive['announcement-history'] = false;

		var url = this.router.url;

		for (let key in this.urlActive) {
			if (url.indexOf(key) != -1) {
				this.urlActive[key] = true;
			}
		}
	}

	logout() {
		localStorage.setItem('auth', '');
		this.router.navigate(["/authentication/signin"])
	}

}
