export class Constants {
    // public static get HOME_URL(): string { return "https://100xlibrary.org/"; };
    public static get HOME_URL(): string { return "https://crossroadscareer.net/"; };
    // public static get API_URL(): string { return "https://100xlibrary.org/mlib/public/"; }; // for production
    public static get LINK_URL(): string { return "https://crossroadscareer.net/#/"; };
    // public static get DEFAULT_IMG(): string { return "https://100xlibrary.org/100xlibraryapis/public/"; };
    // public static get DEFAULT_USR_IMG(): string { return "https://100xlibrary.org/assets/images/default-user.png"; };
    // public static get STRIPE_API_KEY(): string { return "pk_test_bgjMa8UndGwAnyCIe2D0XA5n";};
    // public static get PLUGIN_URL(): string { return "http://mlibrary.s3.amazonaws.com/storage/toDownload/Crossroadscareer.zip";};

    // ############################ for sandbox ###############################
    // public static get PAGE_TITLE(): string { return "Crossroadscareers Library Dev";};
    // public static get API_URL(): string { return "https://100xlibrary.org/100xlibraryapis/public/";};
    // public static get APP_VERSION(): string { return "1.0.0.71";};
    // ############################ for production ############################
    public static get PAGE_TITLE(): string { return "Crossroads Career | Hear God Calling, Get the Right Job and Maximize Your Career"; };
    public static get API_URL(): string { return "https://crossroadscareer.net/sandbox/public/"; };
    public static get APP_VERSION(): string { return "1.3.0.0"; };

    ///  CometChat
    public static get APP_ID(): string { return "2125125f445dfec8" };
    public static get AUTH_KEY(): string { return "ffa2cee37a6b2d7ddca4d5e0da8ad7325e8051e7" };
    public static get REGION(): string { return "US" };

}
