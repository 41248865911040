import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CometComponent } from './comet/comet.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { KitchenSinkAppComponent } from './components/kitchen-sink-app/kitchen-sink-app.component';
import { CometChatConversationListPageComponent } from './components/Pages/comet-chat-conversation-list-page/comet-chat-conversation-list-page.component';
import { CometChatConversationListScreenPageComponent } from './components/Pages/comet-chat-conversation-list-screen-page/comet-chat-conversation-list-screen-page.component';
import { CometChatUnifiedPageComponent } from './components/Pages/comet-chat-unified-page/comet-chat-unified-page.component';
import { GroupListScreenPageComponent } from './components/Pages/group-list-screen-page/group-list-screen-page.component';
import { GroupListComponent } from './components/Pages/group-list/group-list.component';
import { UserListPageComponent } from './components/user-list-page/user-list-page.component';
import { UserListScreenPageComponent } from './components/user-list-screen-page/user-list-screen-page.component';

const routes: Routes = [
  {
    path: '',
    component: CometComponent,
    children: [
      { path: "", redirectTo: "login", pathMatch: "full" },
      {
        path: "login",
        component: KitchenSinkAppComponent,
      },
      {
        path: "home",
        component: HomePageComponent,
      },
      {
        path: "contact-list",
        component: UserListPageComponent,
      },
      {
        path: "conversations-list",
        component: CometChatConversationListPageComponent,
      },
      {
        path: "userListScreen",
        component: UserListScreenPageComponent,
      },
      {
        path: "contact-screen",
        component: UserListScreenPageComponent,
      },
      {
        path: "conversation-screen",
        component: CometChatConversationListScreenPageComponent,
      },
      {
        path: "group-list",
        component: GroupListComponent,
      },
      {
        path: "group-screen",
        component: GroupListScreenPageComponent,
      },
      {
        path: "embedded-app",
        component: CometChatUnifiedPageComponent,
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CometRoutingModule { }
