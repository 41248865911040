import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CometRoutingModule } from './comet-routing.module';
import { SharedModule } from '../shared/shared.module';
import { KitchenSinkAppComponent } from "./components/kitchen-sink-app/kitchen-sink-app.component";
import { FormsModule } from "@angular/forms";
import { HomePageComponent } from "./components/home-page/home-page.component";
import { UserListPageComponent } from "./components/user-list-page/user-list-page.component";
import { CometChatConversationListPageComponent } from "./components/Pages/comet-chat-conversation-list-page/comet-chat-conversation-list-page.component";
import { UserListScreenPageComponent } from "./components/user-list-screen-page/user-list-screen-page.component";
import { CometChatMessageComposerPageComponent } from "./components/Pages/comet-chat-message-composer-page/comet-chat-message-composer-page.component";
import { CometChatGroupListScreenPageComponent } from "./components/Pages/comet-chat-group-list-screen-page/comet-chat-group-list-screen-page.component";
import { CometChatConversationListScreenPageComponent } from "./components/Pages/comet-chat-conversation-list-screen-page/comet-chat-conversation-list-screen-page.component";
import { GroupListComponent } from "./components/Pages/group-list/group-list.component";
import { GroupListScreenPageComponent } from "./components/Pages/group-list-screen-page/group-list-screen-page.component";
import { CometChatUnifiedPageComponent } from "./components/Pages/comet-chat-unified-page/comet-chat-unified-page.component";
import { CometChat } from '@cometchat-pro/chat';
import { Constants as COMETCHAT_CONSTANTS } from '../constants';

import { CometChatConversationList } from "../../cometchat-pro-angular-ui-kit/CometChatWorkspace/src/components/Chats/CometChat-conversation-list/cometchat-conversation-list.module";
import { CometChatConversationListWithMessages } from "../../cometchat-pro-angular-ui-kit/CometChatWorkspace/src/components/Chats/CometChat-conversation-list-with-messages/cometchat-conversation-list-with-messages.module";
import { CometChatGroupList } from "../../cometchat-pro-angular-ui-kit/CometChatWorkspace/src/components/Groups/CometChat-group-list/cometchat-group-list.module";
import { CometChatGroupListWithMessages } from "../../cometchat-pro-angular-ui-kit/CometChatWorkspace/src/components/Groups/CometChat-group-list-with-messages/cometchat-group-list-with-messages.module";
import { CometChatUI } from "../../cometchat-pro-angular-ui-kit/CometChatWorkspace/src/components/CometChatUI/CometChat-Ui/cometchat-ui.module";
import { CometChatUserList } from "../../cometchat-pro-angular-ui-kit/CometChatWorkspace/src/components/Users/CometChat-user-list/cometchat-user-list.module";
import { CometChatUserListWithMessages } from "../../cometchat-pro-angular-ui-kit/CometChatWorkspace/src/components/Users/CometChat-user-list-with-messages/cometchat-user-list-with-messages.module";
import { CometChatAvatar } from "../../cometchat-pro-angular-ui-kit/CometChatWorkspace/src/components/Shared/CometChat-avatar/cometchat-avatar.module";
import { CometComponent } from './comet/comet.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
@NgModule({
  declarations: [
    KitchenSinkAppComponent,
    HomePageComponent,
    UserListPageComponent,
    CometChatConversationListPageComponent,
    UserListScreenPageComponent,
    CometChatMessageComposerPageComponent,
    CometChatGroupListScreenPageComponent,
    CometChatConversationListScreenPageComponent,
    GroupListComponent,
    GroupListScreenPageComponent,
    CometChatUnifiedPageComponent,
    CometComponent,
  ],
  imports: [
    PaginationModule,
    CommonModule,
    SharedModule,
    FormsModule,
    CometRoutingModule,
    CometChatUI,
    CometChatConversationListWithMessages,
    CometChatGroupListWithMessages,
    CometChatUserListWithMessages,
    CometChatConversationList,
    CometChatGroupList,
    CometChatUserList,
    CometChatAvatar,
    ReactiveFormsModule,
    ModalModule,
  ]
})
export class CometModule {

  constructor() {
    /// Seting up Comet chat
    const appSetting = new CometChat.AppSettingsBuilder().setRegion(COMETCHAT_CONSTANTS.REGION).subscribePresenceForAllUsers().build();
    CometChat.init(COMETCHAT_CONSTANTS.APP_ID, appSetting).then(() => {
      console.log('app is ready to work');
    }, (error) => {
      console.log('Error In Init', error);
    });
  }

}
