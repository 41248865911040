import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constants } from '../constants';


@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private url = Constants.API_URL;
  private headers;
  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
  }

  listAll(params: any) {
    return this.http.get(this.url + "notification/all").map(res => res["result"]);
  }

  sendnotification(data) {
    return this.http.post(this.url + "send/notification", data, { headers: this.headers }).map(res => res["result"]);
  }

  listAllRecurring(data, search: any = '') {
    if (search == '') {
      return this.http.get(this.url + "list/recurring", data).map(res => res["result"]);
    } else {
      return this.http.get(this.url + "list/recurring/" + search, data).map(res => res["result"]);
    }
  }

  func(datas) {
    // Extract the time from the date
    const date = new Date(datas?.not_time ? datas?.not_time : datas?.time);

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const payload = JSON.stringify({
      ...datas,
      ...(datas?.time && { time: `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z` }),
      ...(datas?.not_time && { not_time: `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z` }),
      ...(datas?.notify_time && { notify_time: `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z` })
    })

    return this.http.post(this.url + "recurring/save", payload, { headers: this.headers }).map(res => res["result"]);
  }

  delete(data) {
    return this.http.post(this.url + "delete/recurringnotification", data, { headers: this.headers }).map(res => res["result"]);
  }

  pushNotificationLog() {
    return this.http.get(this.url + "notifications/push/log").map(res => res["result"]);
  }
  recurringNotificationLog() {
    return this.http.get(this.url + "notifications/recurring/log").map(res => res["result"]);
  }

  updateAnnouncement(data) {
    return this.http.post(this.url + "updateAnnouncement", data).map(res => res["result"]);

  }
  showAnnouncement() {
    return this.http.get(this.url + "showAnnouncement").map(res => res["result"]);

  }
  showAnnouncementHistory() {
    return this.http.get(this.url + "listAnnouncement").map(res => res["result"]);

  }
  deleteAnnouncementHistory(id: any) {
    return this.http.get(this.url + "deleteAnnouncement/" + id).map(res => res["result"]);

  }
}
