import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comet',
  templateUrl: './comet.component.html',
  styleUrls: ['./comet.component.css']
})
export class CometComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
